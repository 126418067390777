@import "src/variable";

.conclusion-list,
.absence-list,
.my-proposal-list{
  .review-span {
    width: 100%;
    height: fit-content;
    position: absolute;
    background: transparent;

    img {
      visibility: hidden;
    }
  }

}