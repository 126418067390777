@import "src/variable";

.top-heading {
  margin: 1.5rem 0rem;
  @include display_justify_align(flex, null, center);

  &_h4 {
    min-width: 255px;
    @include font-weight_font-size(600, 24px);
  }

  &_hr {
    color: #D7D7D7;
    @include width_height(100%, 1px);
  }

  .add-event {
    padding: 0.7rem 2rem;
    transition: ease-in-out .2s;
    min-width: 12rem;
    @include border-radius_border(45px, none);
    @include display_justify_align(flex, center, center);
    @include color_background-color(white !important, $main-color);

    &_span {
      margin: 0 1rem 0 0;
    }

    &:hover {
      background-color: #27D6A7;
    }
  }
}