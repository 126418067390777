@import "../../../variable";

.actions-filter-buttons{
  @include width_height(100%, 50px);
  @include display_justify_align(flex,flex-end, center);

  label{
    margin-left: 2rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    @include display_justify_align(flex,center, center);

    input{
      margin-right: .5rem;
      @include width_height(18px,18px);
    }

    input[type='radio']:checked:after {
      width: 12px;
      height: 12px;
      border-radius: 15px;
      background-color: #10C998;
      @include position_left_top(relative,3px,3px);
      content: '';
      display: flex;
    }
  }
}