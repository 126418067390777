.ui-table-body-row {
    position: relative;
    height: 52px;
    max-width: 1400px;
    cursor: pointer;

    &:hover {
        box-shadow: 0 4px 4px rgba(0,0,0,.1);
    }

    &:nth-of-type(even) {
        background-color: rgba(0,0,0,.03);
    }

    td{
        white-space: nowrap;
        .description{
            display: inline-block !important;
            margin: 0;
            white-space:nowrap;
            max-width: 250px !important;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    &:hover .ui-table-row-hover-container {
        visibility: visible;
        animation: PopUpAnimation 300ms;
    }
}

.ui-table-body-cell {
    border-width: 0 !important;
    height: 52px !important;
    padding: 14px 19px !important;
    font-size: 16px !important;
    line-height: 19px !important;
    cursor: pointer;
}

.ui-table-body-custom-element {
    border-width: 0 !important;
    height: 52px !important;
    font-size: 16px !important;
    line-height: 19px !important;
    padding: 0 !important;
}

.ui-table-row-hover-container {
    height: 52px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    position: absolute;
    right: 1rem;
    z-index: 100;
}