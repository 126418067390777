.open_modal{
  position: fixed !important;
  bottom : 10px;
  right: 10px !important;
  zoom: 1.25;
}

.add_icon{
  fill : white;
  color: white;
}