.date_container{
  margin-bottom: 32px;

  .modal_heading{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1D1D1D;
    margin-bottom: 6px;
  }

  .date{
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #3E3E3E;
  }

}