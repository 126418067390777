.proposals-hover {
    display: flex;
    align-items: center;
    z-index: 100 !important;
    button{
        margin: 0 !important;
        padding: 0 !important;
        border: 0 !important;
        height: auto !important;

        img{
            height: auto !important;
            width: auto !important;
            margin: 0 7.5px !important;
        }
    }
}