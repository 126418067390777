.employee_data{

  margin-top: 30px;
  margin-bottom: 25px;

  .modal_heading{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1D1D1D;
    margin-bottom: 11px;
  }

  .employee_name{
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #292929;
    margin: 0;
  }

  .data_position{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #757575;
  }

}