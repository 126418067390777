.header{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap : 13px;
  padding-bottom: 17px;
  border-bottom: 1px solid #E9E9E9;

  h4{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #414141;
    margin: 0;
    padding: 0;
  }

  h6{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #818181;
    padding: 0;
    margin: 0;
  }

}