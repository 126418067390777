.header{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  width: 100%;
  text-align: center;
  color: #414141;
  padding-bottom: 18px;
  border-bottom: 1px solid #E9E9E9;
  margin-bottom: 16px;
}

.form{

  .header{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    width: 100%;
    text-align: center;
    color: #414141;
    padding-bottom: 18px;
    border-bottom: 1px solid #E9E9E9;
    margin-bottom: 16px;
  }

  .footer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .disabled_submit{
      cursor: not-allowed;
      background-color: #F7F7F7;

      p{
        color : rgba(75, 75, 75, 0.58);
      }
    }

  }

}