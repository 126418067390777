.MuiTableSortLabel-root,
.MuiButtonBase-root.MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: #1A1A1A !important;
}

.MuiButtonBase-root.MuiTableSortLabel-root {
    > svg {
        display: none;
    }
}

.ui-table-head-cell {
    padding: 0 19px !important;
    height: 40px !important;
    font-size: 16px !important;
    line-height: 20px !important;
    font-weight: 500 !important;
    white-space: nowrap;

    &:nth-child(1){
        padding: 0;
    }
}
