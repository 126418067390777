.modal_block {
  width: 637px;
  height: 100%;

  .footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .cancel_button{
      &:hover,&:hover{
        padding: 12px 24px;
      }
    }
  }
}