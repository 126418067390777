@import "src/variable";

.month-changer {
  transform: translateY(-10px);
}

.current-month {
  line-height: 20px;
  color: #484848;
  border-radius: 25px;
  text-align: center;
  @include padding_min-width(6px 0, 210px);
  @include display_justify_align(flex, center, center);
  @include font-weight_font-size(bold, 18px);
  // transform: scale(.8);
}

.prev,
.next {
  border: none;
  display: flex;
  @include color_background-color(#10C998, transparent);

  .MuiSvgIcon-root {
    font-size: 3rem;
  }
}

.month-name {
  font-weight: 600;
  padding: 0;
  min-width: 180px;
}

.today-button {
  border: none;
  margin-left: 20px;
  line-height: 20px;
  @include color_background-color($table-color, transparent);
  @include font-weight_font-size(500, 18px);

  p{
    margin: 0;
    &:hover{
      border-bottom: 1px solid #10C998;
    }
  }

  &.black-button {
    color: #212529;
    cursor: default;
    p{
      &:hover{
        border-bottom: none;
      }
    }
  }
}
