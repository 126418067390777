@import "src/variable";

.list,
.conclusions {
  border: none;
  background-color: transparent;
  font-size: 24px;
  border-bottom: 3px solid transparent;

  &:hover {
    border-bottom: 3px solid $main-color;
  }
}

.list {
  white-space: nowrap;
}

.conclusions {
  margin-right: 15px;
  width: max-content;
}

.chooseDate {
  padding-top: 20px;
  @include display_justify_align(flex, flex-end, center);


  .datepicker-block{
    max-width: 100% !important;
    div{
      flex-direction: row !important;
    }

    .MuiFormControl-root{
      margin-right: 1rem;
      display: flex;
      flex-direction: column !important;

      &:last-child{
        margin-right: 0;
      }

      label{
        display: flex !important;
        overflow: visible !important;
        margin-bottom: 0 !important;
      }
    }
  }
}

.vertical-divider {
  border-left: 1px solid rgba(0,0,0,.25);
  height: 25px;
  margin: 0 20px 0 8px;
}

.top-bar {
  padding-top: 3rem;
  @include display_justify_align(flex, null, center);
}

.number-of-conclusions {
  border-radius: 15px;
  line-height: 14px;
  @include width_height(20px, 20px);
  @include font-weight_font-size(500, 14px);
  @include position_right_top(absolute, -6px, -9px);
  @include color_background-color(white, $main-color);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  * {
    margin: 0;
  }
}

.number-my-proposals {
  transform: translateX(5px);
}

.history-filter-bar{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  p{
    white-space: nowrap;
  }

}