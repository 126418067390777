.show-absence-type {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 12px;

    .show-absence-type-status {
        width: 110px;
        height: 27px;
        border-radius: 27px;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.show-absence-type-status-remote {
            background-color: #FFC46B;
            color: #393939;
        }

        &.show-absence-type-status-holiday {
            background-color: #F36666;
            color: #F9F9F9;
        }

        &.show-absence-type-status-sick-leave {
            background-color: #2978A0;
            color: #F9F9F9;
        }
    }
}
