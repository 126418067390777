@import "src/variable";

.backdrop{
  background: rgba(12, 12, 12, 0.5) !important;
  ::-webkit-scrollbar {
    width: 7px !important;
  }

  .modal{
    display: block;
  }
}