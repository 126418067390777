@import "src/variable";

.absence-filtering-area{
  position: relative;
  width: 100%;
  max-height: 100px;
  background-color: #F8F8F8;
  padding: 13px 20px;

  .absence-filter-input-area{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .clear-tags{
      white-space: nowrap;
      margin-left: 10px;
      display: flex;
      align-items: center;

      p{
        margin: 0 !important;
        text-decoration: underline;
      }
    }

    .absence-filer-input{
      position: relative;
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      overflow: hidden;

      .input-form_div-flex{
        display: none;
      }

      input{
        position: relative;
        width: 100%;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #C4C4C4;
        border-radius: 20px;
        padding: 0 30px;
        font-size: 16px;
        line-height: 22px;
        color: #262626;
      }

      .input-clear-icon{
        position: absolute;
        right: 15px;
        height: 16px;
      }

      .input-search-icon{
        position: absolute;
        left: 8px;
        top: 8px;
        width: 16px;
        height: 16px;
        z-index: 20;
      }
    }

    .filtered-list-dropdown-menu{
      position: absolute;
      z-index: 10000;
      top: 40px;
      left: 0;
      width: 100%;
      background-color: white;
      min-height: 60px;
      height: auto;
      max-height: 320px;
      box-shadow: 0 0 10px rgba(51, 115, 128, 0.26);

      .filtered-list-dropdown{
        overflow-y: auto;
        background-color: white;
        padding: 25px 20px;
        min-height: 60px;
        height: auto;
        max-height: 320px;

        .option-group{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .option-header{
            margin: 12px 0;
            color: #ACACAC;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
          }

          .option{
            padding-left: 16px;
            display: flex;
            width: auto;
            outline: none;
            background-color: white;
            border: none;
            span{
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 6px;
            }
          }
        }


      }

      &::before{
        content: '';
        @include position_left_top(absolute,calc(50% - 10px),-6px);
        @include width_height(20px,20px);
        transform: rotate(45deg);
        background-color: white;
        box-shadow: 0 0 10px rgba(51, 115, 128, 0.26);
        z-index: -2;
      }
    }

  }

  .filters-list{
    width: 100%;
    height:  50px;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    &::-webkit-scrollbar-thumb{
      display: none;
    }

    .filter-item {
      display: flex;
      height: 24px;
      flex-direction: row;
      align-items: center;
      width: auto;
      padding: 2px 13px;
      background-color: #AAAAAA;
      border-radius: 16px;
      margin-right: 10px;

      &:last-child{
        margin-right: 0px;
      }

      p{
        margin: 0;
        padding: 0;
        font-size: 12px;
        line-height: 22px;
        color: #FFFFFF;
        white-space: nowrap;
        margin-right: 14px;
      }

      .filter-remove-icon{
        width: 24px;
        height: 24px;
        padding: 4px;
      }

    }
  }


}