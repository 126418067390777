.replacement{
  margin-bottom: 32px;

  .modal_heading{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1D1D1D;
    margin-bottom: 6px;
  }

  .employee{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #5F5F5F;
  }

}