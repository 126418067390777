.bar_option{
  position: relative;
  margin-right: 25px;
  height: 50px;
  display: flex;
  align-items: center;

  .option{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #313131;
    background-color: white;
    border : none;
    outline : none;
    white-space: nowrap;
    padding: 0 5px;
  }

  .selected{
    font-weight: 600;
    color: #212121;
    padding: 0 2px;
  }

  .number{
    position: absolute;
    top : 0;
    right: -25px;
    width: 22px;
    height: 22px;
    background: #10C998;
    border-radius: 20px;
    color : white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

}

.without_number{
  margin-right: 8px;
}