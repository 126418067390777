
.modal_body{
  position: relative;

  .footer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .edit_icon{
    position: absolute;
    right: 0;
  }

}