.modal_block{
  width: 634px;
  height: 100%;

  .footer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .decision_buttons{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap : 25px;

      .cancel_button{
        &:hover,&:hover{
          padding: 12px 24px;
        }
      }
    }
  }
}