.modal_block {
  width: 634px;
  height: 100%;

  .footer{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
