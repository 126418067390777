.holiday_type {
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;

  .section_header {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1D1D1D;
    margin: 0;
  }


  .holiday_types, .holiday_types_mobile{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 14px 8px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .holiday_types_mobile{
    flex-wrap : nowrap;
    overflow-x: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .type{
      min-width: auto;
      padding: 0 25px;
      p{
        white-space: nowrap;
      }
    }

  }

  .holiday_types,
  .holiday_types_mobile {

    .type{
      background: #FFFFFF;
      border: 1px solid #DFDFDF;
      box-sizing: border-box;
      border-radius: 25px;
      height: 40px;
      width: 206px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 25px;

      p{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #525252;
        margin: 0;
      }

    }

    .type_selected{
      background: #F7F7F7;
      border: 2px solid #6D6D6D;
      box-sizing: border-box;
      border-radius: 25px;
      padding: 0 24px;

    }

    .type_hover:hover{
      background-color: #FFFFFF;
      border: 1px solid #6D6D6D;
      border-radius: 25px;
    }

  }



  .holiday_error{
    position: relative;
    top: 10px;
    width: 100%;
  }

}