.absence_status {
  width: 100%;
  border: 1px solid #DFDFDF;
  border-radius: 25px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;



  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #616161;
    margin: 0;
  }
}

.absence_status_mobile{
  min-width: 150px;
  width: 100%;
  border: 1px solid #DFDFDF;
  border-radius: 25px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #616161;
    margin: 0;
  }
}

.absence_status_HOLIDAY {
  background-color: #F36666;
  border: none;

  p {
    color: #FFFFFF;
  }
}

.absence_status_REMOTE {
  background-color: #FFC46B;
  border: none;

  p {
    color: #FFFFFF;
  }
}

.absence_status_SICK_LEAVE {
  background-color: #2978A0;
  border: none;

  p {
    color: #FFFFFF;
  }
}

.absence_status_HOLIDAY_hover:hover {
  border: 2px solid #F36666;
  margin: 0 0 0 -1px;
}

.absence_status_REMOTE_hover:hover {
  border: 2px solid #FFC46B;
  margin: 0 -1px 0 -1px;
}

.absence_status_SICK_LEAVE_hover:hover {
  border: 2px solid #2978A0;
  margin: 0 -1px 0 0;
}