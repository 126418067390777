body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: 'Poppins', sans-serif !important;
}
*:focus{
  outline: none !important;
  box-shadow: none !important;
}
textarea{
  resize: none;
}