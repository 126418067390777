.top_bar{
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  height: 50px;
  scrollbar-width: none;
  padding: 0 25px;
  gap: 2px;

  &::-webkit-scrollbar {
    display: none;
  }
}