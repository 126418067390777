.modal_block {
  width: 637px;
  height: 100%;

  .header {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: #393939;
    padding-bottom: 25px;
    border-bottom: 1px solid #E9E9E9;
  }

  .footer{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;


    .disabled_submit{
      background-color: lightgrey;
      cursor: not-allowed;

      &:hover, &:focus {
        background-color: lightgrey;
        padding: 16px 25px;
        border: none;
      }
    }
  }
}