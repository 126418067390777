.header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  height: 130px;
  padding-bottom: 20px;
  border-bottom: 1px solid #E9E9E9;

  .vertical_line{
    width: 6px;
    height: 100%;
    border-radius: 20px;
  }

  .HOLIDAY{
    background-color: #F36666;
  }

  .SICK_LEAVE{
    background-color: #2978A0;
  }

  .REMOTE{
    background-color: #FDD598;
  }


  .header_container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 7px;

    .tittle{
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 33px;
      color: #393939;
      margin: 0;
    }

    .date{
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      color: #1D1D1D;
      margin: 0;
    }

  }

}
