.employee_projects{
  margin-bottom: 32px;

  .modal_heading{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #1D1D1D;
  }

  ul{
    margin-left: 18px;

    .data{
      font-style: italic;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #5F5F5F;
    }
  }

}