.proposal_status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap : 8px;

    .title{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #4B4B4B;
        margin: 0;
    }

    .image{
        margin: 0;
    }

}