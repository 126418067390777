.absence_types{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap : 8px;
}

.absence_types_mobile{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  overflow-x: auto;
  gap : 8px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

}


.absence_error{
  position: relative;
  top: 24px;
  width: 100%;
}