@import "src/variable";

.backdrop{
  background: rgba(12, 12, 12, 0.5) !important;
  padding: 0 !important;

  ::-webkit-scrollbar {
    width: 7px !important;
  }

  .modal{
    display: block;
    padding: 50px 15px 150px 15px !important;
    border-radius : 0 !important;
    width: 100vw !important;
    height: 100vh !important;
  }

}
