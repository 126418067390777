.create_proposal_body{
  margin: 0;
  padding : 0;

  .error{
    margin-bottom: .5rem;
  }

  .section_header{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1D1D1D;
    margin-bottom: 13px;
  }

  .select_employee{
    position: relative;
    width: 100%;
    z-index: 100;

    p{
      margin: 0;
    }

  }

  .absence_status{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 28px;
  }

  .description_fieldset{
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 10px;
    margin-bottom: 28px;

    legend {
      width: auto;
      display: flex;
      margin: 0 0 0 17px;
      padding: 0 5px;
      z-index: 100;
    }

    .description{
      position: relative;
      border: none;
      margin: 0;
      width: 100%;
      padding: 5px 15px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #585858;
      resize: none;
      height: 100px;
      background-color: transparent;
    }

  }

}