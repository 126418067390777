.holiday_type{

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 20px;

  .modal_heading{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1D1D1D;
    margin-bottom: 0;
  }

  .type{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1D1D1D;
    margin: 0;
  }
}