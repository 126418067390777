@import "src/variable";

@keyframes OptionsDropDownAnimation {
  0%{
    opacity: 0;
    max-height: 0;
  }

  100%{
    opacity: 1;
    max-height: 136px;
  }
}

@keyframes OptionsItemsDropDownAnimation {
  0% {
    opacity: 0;
    max-height: 0;
    background-color: transparent;
    transform: translate(-20%,-50%);
  }

  70%{
    background-color: white;
  }

  100% {
    opacity: 1;
    max-height: 100%;
    background-color: white;
    transform: translate(0,0%);
  }
}

@keyframes FilterMenuAnimation {
  0%{
    max-height: 0;
    opacity: 0;
  }

  100%{
    max-height: 320px;
    opacity: 1;
  }
}

@keyframes FilterMenuOptionAnimation {
  0%{
    opacity: 0;
    transform: translate(-10%,-20%);
  }

  100%{
    opacity: 1;
    transform: translate(0%,0%);
  }
}

.dropdown-arrow{
  &::before{
    content: '';
    @include position_left_top(absolute,calc(50% - 10px),-6px);
    @include width_height(20px,20px);
    transform: rotate(45deg);
    background-color: white;
    box-shadow: 0 0 10px rgba(51, 115, 128, 0.26);
    z-index: -2;
  }
}

.filtering-engine-area {
  position: relative;

  .clear-tags{

    display: flex;
    align-items: center;

    p{
      margin-left: 1rem;
      margin-bottom: 0 !important;
      text-decoration: underline;
      transition: all .3s ease-in-out;

      &:hover{
        color : #10C998;
      }
    }
  }

  .MuiSelect-icon {
    color: $main-color;
  }

  .options-dropdown {
    position: relative;
    background-color: white;

    .options-button{
      position: relative;
      @include border-radius_border(25px 0 0 25px, 1px solid #C4C4C4);
      @include padding_min-width(2px 4px 2px 20px, 135px);
      @include width_height(100%,100%);
      outline: none;
      @include display_justify_align(flex,space-between,center);
      background-color: #F4F4F4;
      transition: all .25s ease-in-out;

      &:hover{
        background-color: #e5e5e5;
      }

      p{
        margin: 0;
      }
      img{
        margin-right: 5px;
      }

      .dropdown-open-icon{
        transform: rotate(180deg);
        transition: all .3s ease-in-out;
      }
    }

    .options-dropdown-list{
      @include position_left_top(absolute, 0, calc(100% + 15px));
      max-width: 135px;
      max-height: 136px;
      padding: 0;
      list-style: none;
      background: white;
      z-index: 100;
      box-shadow: 0 0 10px rgba(51, 115, 128, 0.26);
      animation : OptionsDropDownAnimation .5s;

      @extend .dropdown-arrow;

      .list-item{
        @include padding_min-width(2px 4px 2px 20px, 135px);
        width: 100%;
        display: flex;
        cursor: pointer;
        background-color: white;
        z-index: 100;
        animation : OptionsItemsDropDownAnimation .7s;

        &:nth-child(1){
          @include padding_min-width(14px 4px 2px 20px, 135px);
        }

        &:last-child {
          @include padding_min-width(2px 4px 14px 20px, 135px);
        }

        p{
          width: auto;
          margin: 0;
        }

        &:hover {
          p{
            border-bottom: 1px solid $main-color;
          }
        }
      }
    }
  }

  .MuiSelect-select {
    text-align: center;
  }

  .input-form {
    position: relative;
    height: auto;
    border: 1px solid #C4C4C4;
    background: transparent;
    display: flex;
    align-items: center;

    &_div-flex{
      color: gray;
      @include display_justify_align(flex,flex-start,center);
      @include position_right_bottom(absolute, 0, 0);
      @include width_height(auto, 100%);
      @include padding_min-width(2px 4px 2px 20px, 300px);
      &_div-inline {
        color: #B6B6B6;
        z-index: 1;
        margin-top: 100%;
        display: inline-block;
        @include position_right_bottom(absolute, 0, 0);
        @include width_height(100%, auto);
        @include padding_min-width(2px 4px 2px 20px, 300px);

        .disappear {
          opacity: 0 !important;
        }
      }
    }

  }

  .search-input {
    z-index: 2;
    border: none;
    background: transparent;
    height: auto;
    @include position_left_top(relative, 0, 0);
    @include padding_min-width(2px 4px 2px 20px, 300px);
  }

  .enter-arrow {
    background-color: $main-color;
    border-radius: 0 25px 25px 0 !important;
    transition: all .25s ease-in-out;

    &:hover{
      background-color: #27D6A7;
    }

  }

  .dropdown-filter-box{
    box-shadow: 0 0 10px rgba(51, 115, 128, 0.26);
    background-color: white;
    z-index: 1000000;
    @include position_left_top(absolute, 0, 4rem);
    @include padding_min-width(0px, 315px);

    @extend .dropdown-arrow;

  }

  .dropdown-filter-menu {
    background-color: white;
    padding: 20px;
    width:  100%;
    overflow-x: auto;
    max-height: 320px;
    animation : FilterMenuAnimation .3s;

    .option {
      padding: 5px;
      cursor: pointer;
      animation : FilterMenuOptionAnimation .7s;

      &:disabled:hover {
        border-bottom: none !important;
        cursor: default !important;
      }

      &:hover {
        border-bottom: 1px solid $main-color;
      }

    }

    .disabled-option{
      animation : FilterMenuOptionAnimation .3s;
    }


  }
}

.MuiPaper-root {
  padding: 10px 15px;
}

.MuiPaper-elevation8{
  box-shadow: 0px 0px 15px rgba(51, 115, 128, 0.4) !important;
}

.MuiPaper-root .MuiList-root {
  option:hover {
    border-bottom: 1px solid $main-color;
    cursor: pointer;
  }
}

.MuiListItem-root.Mui-selected {
  background-color: transparent !important;
}

.MuiListItem-root.Mui-selected:hover, .MuiListItem-button:hover {
  color: black;
  background-color: transparent !important;
  border-bottom: 1px solid #10C998;
}

.filtering-engine{
  min-height: 115px;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

.filter-block {
  display: flex;
  min-height: 115px;
  align-items: center;
  padding: 0 !important;
  margin: 0 !important;

  .chosen-filter {
    border-radius: 16px;
    @include padding_min-width(0 10px, 87px);
    @include display_justify_align(flex, space-between, center);
    @include color_background-color(white, #AAAAAA);
    font-size: 12px;
    font-weight: 400;
    height: 27px;

    .clear-icon-container {
      margin-left: 10px;
    }
  }

  .filter-block-filters {
    display: flex;
    height: 50px;
  }

  .clear-filters {
    margin: 0 0 0 14px;
    text-decoration: underline;
    transform: translateY(-1.5px);
    white-space: nowrap;
  }
}

.options-dropdown {
  background-color: #F4F4F4;
}

.nav-icon {
  filter: grayscale(10);
  position: absolute;
  cursor: pointer;
  background-color: white !important;

  .MuiSvgIcon-root {
    font-size: 3rem;
  }

  &.prev {
    left: -10px;
  }

  &.next {
    right: -10px;
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.filter-block-engine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  scroll-behavior: smooth;

  &.padding .filter-block-filters {
    padding: 0 48px;
  }
}

.chosen-filter {
  border-radius: 16px;
  @include padding_min-width(0 10px, 87px);
  @include display_justify_align(flex, space-between, center);
  @include color_background-color(white, #AAAAAA);
  font-size: 12px;
  font-weight: 400;
  height: 27px;
  margin-right: 14px;
  flex-shrink: 0;

  .clear-icon-container {
    margin-left: 10px;
  }

  .filter-title {
    white-space: nowrap;
  }
}

.chosen-filter-placeholder {
  height: 27px;
  width: 0;
  margin: 0;
  padding: 0;
}

.filter-block-filters {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  max-width: calc(100vw - 800px);
  width: 900px;
  min-width: 600px;
  height: 27px;
  scroll-behavior: smooth;
}
