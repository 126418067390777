.action_made_by_container{

  margin-top: 18px;
  margin-bottom: 18px;

  .modal_heading{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1D1D1D;
    margin : 0;
  }

  .action_made_by{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    p{
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #292929;
    }
  }


}