@import "src/variable";

.color-status {
  text-align: center;
  border-radius: 25px;
  max-width: 160px;
  margin: auto;
  @include padding_min-width(7px, 160px);

  &_button {
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
  }
}

.vacation-status {
  @include color_background-color(white, $vacation-color);
}

.remote-work-status {
  background-color: $remote-work-color;
}

.sick-leave-status {
  @include color_background-color(white, $sick-leave-color);
}