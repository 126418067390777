@import "src/variable";

.clear-icon-container-input {
  position: absolute;
  z-index: 200;
  top: 0;
  right: 0;
  bottom: 0;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: .7;
  animation: PopUpAnimation .25s;
}

.top-heading {
  margin: 1.5rem 0rem;
  @include display_justify_align(flex, null, center);

  &_h4 {
    min-width: 255px;
    @include font-weight_font-size(600, 24px);
  }

  &_hr {
    color: #D7D7D7;
    @include width_height(100%, 1px);
  }

  .add-event{
    width: 240px !important;
    margin-left: 1.5rem;
    padding: .6rem 5rem;

    svg{
      fill : white;
    }

    p{
      white-space:nowrap;
    }
  }
}

.calendar-block {
  .absence {
    cursor: pointer;
    z-index: 100;
  }

  .months-block {
    margin-bottom: 3rem;
    @include display_justify_align(flex, space-between, center);
  }

  .filter-block {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 100px;
  }

}

.months-block {
  @include display_justify_align(flex, space-between, center);
}

.pagination-block {
  flex-wrap: wrap;
  @include display_justify_align(flex, space-between, null);
}

.clear-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: ease-in-out .2s;

  &:hover {
    color: $main-color;
  }

  .clear {
    border-bottom: 1px solid !important;
  }
}

.planned-absence-color {
  border-radius: 30px;
  overflow: hidden;
  background-size: 0px 100%, 100% 30px, 0px 100% , 100% 0px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  background-image: repeating-linear-gradient(-90deg, gray, gray 4px, transparent 4px, transparent 8px, black 8px),
  repeating-linear-gradient(-45deg, gray, gray 4px, transparent 4px, transparent 8px, black 8px),
  repeating-linear-gradient(-90deg, gray, gray 4px, transparent 4px, transparent 8px, black 8px),
  repeating-linear-gradient(-45deg, gray, gray 4px, transparent 4px, transparent 8px, black 8px);
}

.calendar-box{
  overflow: hidden;
  max-width: 1400px;
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

.calendar-container{
  @include position_left_top(relative,0, 0);

  .render-calendar{
    @include display_justify_align(flex,space-between,null);

    .users-container{
      @include position_left_top(sticky,0, 0);
      min-width: 200px;
      width: 100%;
      z-index: 30;
      background-color: white;
      border-right: 5px solid white;

      .empty{
        @include width_height(100%,36px);
        background-color: white;
      }

      .empty-head{
        @include position_left_top(sticky,0, 0);
        z-index: 10;
      }

      .empty-foot{
        @include position_left_bottom(sticky,0,0);
        z-index: 10;
      }

      .sticky-user{
        @include position_left_top(sticky,0, 36px);
        z-index: 10;
        &::before{
          content: '';
          @include position_left_bottom(absolute,0, 0);
          @include width_height(120%,1px);
          border-bottom: 1px solid #D7D7D7;
        }
      }

      .sticky-user-hover{
        @include position_left_top(sticky,0, 36px);

        &::before{
          content: '';
          @include position_left_top(absolute,0,0px);
          @include width_height(100%, 100%);
          background-color: rgba(159, 213, 194, 0.3);
          pointer-events: none;
        }
      }

      .user-hover{
        position: relative;

        &::before{
          content: '';
          @include position_left_top(absolute,0,0px);
          @include width_height(100%, 100%);
          background-color: rgba(159, 213, 194, 0.3);
          pointer-events: none;
        }
      }

      .sticky-user,
      .user{
        @include width_height(auto,46px);
        padding: 0 21px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        line-height: 17px;

        &:nth-child(even){
          background-color: #F8F8F8;
        }
      }
    }

    .calendar-month{
      @include position_left_top(relative,0,0);
      @include width_height(auto,100%);

      .calendar-days-head{
        @include position_right_top(sticky,0,0);

        .hover-day{
          background-color: rgba(159, 213, 194, 0.3);
          border-radius: 30px 30px 0 0;
        }
      }

      .calendar-days-foot{
        @include position_right_bottom (sticky,0,0);

        .hover-day{
          background-color: rgba(159, 213, 194, 0.3);
          border-radius: 0 0 30px 30px;
        }
      }

      .calendar-days{
        @include width_height(100%,36px);
        background-color: white;
        z-index: 12;

        .days{
          @include width_height(auto,100%);
          @include display_justify_align(flex,flex-start,center);


          .sticky-day{
            @include position_left_top(sticky,0, 36px);
          }

          .current-day-head{
            border : 2px solid #10C998;
            z-index: 10;
            border-bottom: 1px solid white;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            color: #10C998;
          }

          .current-day-foot{
            border : 2px solid #10C998;
            border-top: 1px solid white;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
            color: #10C998;
          }
          .calendar-day{
            min-width: 36px;
            height: 100%;
            @include display_justify_align(flex,center,center);


          }
        }
      }

      .calendar-proposals{
        position: relative;

        .calendar-proposal-hover{
          position: relative;

          &::before{
            content: '';
            @include position_left_top(absolute,0px,0);
            @include width_height(100%, 100%);
            background-color: rgba(159, 213, 194, 0.3);
            pointer-events: none;
            z-index: 2;
          }
        }

        .calendar-proposal{
          @include width_height(100%,46px);
          position: relative;

          &:nth-child(odd){
            background-color: #F8F8F8;
          }
          &:nth-child(1){
            @include position_left_top(sticky,0, 36px);
            background-color: #F8F8F8;
            border-bottom: 1px solid #D7D7D7;
            z-index: 20;
          }

          .calendar-days-row{
            display: flex;
            flex-direction: row;
            @include width_height(100%,46px);

            .weekend{
              background-color: #EEEEEE;
              border: none !important;
            }

            .day-cell-hover{
              &::before{
                content: '';
                @include position_left_top(absolute,0,0);
                @include width_height(100%, 100%);
                background-color: rgba(159, 213, 194, 0.3);
                pointer-events: none;
              }
            }

            .weekend,
            .calendar-day-cell{
              position: relative;
              min-width: 36px;
              height: 46px;
              z-index: 1;
              cursor: pointer;
              border-right: 1px solid #F3F2F2;

              &:last-child{
                border-right: none;
              }
            }
          }
          .calendar-proposal-row{
            @include position_left_top(absolute,0, 0);
            @include width_height(100%,46px);
            @include display_justify_align(flex,null,center);
            overflow: hidden;
            cursor: pointer;


            .calendar-absence-cell,{
              position: absolute;
              height: 20px;
              border-radius: 30px;
              z-index: 2;
            }

            .calendar-proposal-cell{
              position: absolute;
              height: 20px;
              border-radius: 30px;
              overflow: hidden;
              z-index: 2;
              background-size: 0px 100%, 100% 30px, 0px 100% , 100% 0px;
              background-position: 0 0, 0 0, 100% 0, 0 100%;
              background-repeat: no-repeat;
            }
          }

          .current-day{
            @include position_left_top(absolute,0, 0);
            @include width_height(36px,46px);
            border: 2px solid #10C998;
            border-top: none;
            border-bottom: none;
            pointer-events: none;
            z-index: 10;
          }

        }

      }
    }
  }

}