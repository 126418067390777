@import "src/variable";

.absence_types{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap : 25px;
  margin-top: 10px;

  .type{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    p{
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #202020;
      margin: 0;
    }

    .color_dashed{
      @include width_height(40px, 16px);
      border-radius: 30px;
      overflow: hidden;
      background-size: 0 100%, 100% 30px, 0 100% , 100% 0;
      background-position: 0 0, 0 0, 100% 0, 0 100%;
      background-repeat: no-repeat;
      background-color: gray;
      background-image: repeating-linear-gradient(-90deg, white, white 4px, transparent 4px, transparent 8px, black 8px),
      repeating-linear-gradient(-45deg, white, white 4px, transparent 4px, transparent 8px, black 8px),
      repeating-linear-gradient(-90deg, white, white 4px, transparent 4px, transparent 8px, black 8px),
      repeating-linear-gradient(-45deg, white, white 4px, transparent 4px, transparent 8px, black 8px);
    }

    .color{
      @include width_height(40px, 16px);
      border-radius: 30px;
      overflow: hidden;
    }

    .vacation-status {
      border: none;
      @include color_background-color(white, $vacation-color);
    }

    .remote-work-status {
      border: none;
      background-color: $remote-work-color;
    }

    .sick-leave-status {
      border: none;
      @include color_background-color(white, $sick-leave-color);
    }

  }

}