.proposals-hover {
    & > * {
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .review-proposal .review-span img {
        height: 24px;
        margin-bottom: 4px;
    }

    .edit-block {
        margin: 0 !important;
    }

    .cancel-proposal img {
        margin: 0 !important;
        height: 20px;
    }
}