@import "src/variable";

.devapo-button {
    width: 150px;
    height: 40px;
    outline: 0;
    border: 0;
    margin: 0;
    padding: 0;
    border-radius: 20px;
    color: white;
    background-color: #10C998;
}

body{
    p{
        margin: 0;
        padding: 0;
    }
}

.proposal-modal-padding-bottom {
    margin-bottom: 30px;
}

::-webkit-scrollbar {
    width: 12px !important;
}

::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: #D7D7D7;
    @include border-radius_border(7px, 4px solid rgba(0, 0, 0, 0));
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}