@import "src/variable";

.top_bar{
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scrollbar-width: none;
  padding: 0 25px;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;

  &::-webkit-scrollbar {
    display: none;
  }

  .proposal_button {
    @include border-radius_border(20px, 1px solid #D3D3D3);
    outline : none;
    background-color: white;
    border: 1px solid #D3D3D3;
    box-sizing: border-box;
    border-radius: 45px;
    padding: 2px 11px;
    height: 28px;
  }

  .active {
    border: 1px solid $main-color !important;
    @include color_background-color(white, $main-color !important);
  }

}