
.reason_container{
  width: 100%;
  margin-top: 32px;
  margin-bottom: 28px;

  .reason_fieldset{
    width: 100%;
    display: flex;
    background: #FFFFFF;
    padding-bottom: 2px;
    border: 1px solid #DFDFDF;
    border-radius: 10px;

    legend{
      width: auto;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #F36666;
      margin-left: 20px;
      padding: 2px;
      margin-bottom: 0;
    }

    textarea{
      height: 130px;
      width: 100%;
      border : none;
      outline : none;
      resize: none;
      background-color: transparent;
      padding: 0 10px 10px 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #1D1D1D;
    }

  }

}