.modal_body{

  .footer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .icon{
      color : white;
    }

    .disabled_submit{
      cursor: not-allowed;
      background-color: #F7F7F7;

      p{
        color : rgba(75, 75, 75, 0.58);
      }
    }

  }

}