.mainContainer{
    margin: 0.5rem 0;
    width: 100%;
}

.partialContainer {
    max-width: 1450px;
    margin: 0 auto;
    padding: 0 3.5rem;
}

@media (min-width: 1500px){
    .mainContainer{
        margin: auto;
    }
}
.MuiInput-underline:before,
.MuiInput-underline:after {
    border-bottom: none !important;
}

.close {
    position: sticky !important;
    top: 0 !important;
    right: 0 !important;
    transform: translate(40px, -40px);
}

.font-weight-semibold {
    font-weight: 500 !important;
}
hr {
    margin: 0 !important;
}

.flex {
    flex-grow: 1;
}
