.topBar {
    height: 80px;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .devapoButton {
        margin-left: 20px;
        width: 200px;
    }
}
