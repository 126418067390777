.ui-table-head-icon {
    margin: 0 9px;
    
    &.inverted {
        transform: rotate(180deg);
    }
    
    &.hidden {
        visibility: hidden;
    }
}
