.ui-table-container {
    position: relative !important;
    overflow-x: initial !important;

    table .MuiTableFooter-root tr nav ul{
        margin-top: 10px;
        margin-bottom: 100px;
    }

    table .MuiTableFooter-root tr nav{
        position: absolute;
        right: 0;
    }
}
