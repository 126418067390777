.modal_block {
  width: 637px;
  height: 100%;

  .body {
    margin-top: 28px;
  }

  .footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .cancel_button{
      &:hover,&:hover{
        padding: 12px 24px;
      }
    }

    .disabled_submit {
      background-color: lightgrey;
      cursor: not-allowed;

      &:hover, &:focus {
        background-color: lightgrey;
        padding: 16px 25px;
        border: none;
      }
    }
  }
}