.declined_reason_container{
  margin-bottom: 19px;

  .modal_heading{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1D1D1D;
    margin: 0;
  }

  .reason{
    overflow-wrap: break-word;
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #5F5F5F;
  }

}