@import "src/variable";

.employee-list {

  .absence-list,.my-proposal-list,.proposal-list{
    &:nth-child(odd){
      background-color: #F8F8F8;
    }
  }

  .color-status {
    width: 10px;
    height: 10px;
    min-width: 0;

    p {
      display: none;
    }
  }

  .employee-record {

    height: 38px !important;
    margin: 0 1.5rem 0rem !important;
    padding: 5px 0;
    @include display_justify_align(flex, space-between, center);


    .absence-type, .employee-name{
      white-space: nowrap;
    }

    .short-date{
      white-space: nowrap;
    }
  }

  .proposal-status_title {
    display: none;
    color : #cc2828 !important;
  }
}

.proposal-status_title {
  color : #5f5f5f !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.my-proposal-list {
  margin-top: 0 !important;
  .employee-record {
    margin-bottom: 0 !important;


    .proposal-status{
      img{
        height: 20px !important;
      }
    }

    .show-proposal-history-status{
      svg{
        height: 20px !important;
      }
    }
  }

  .declined-message {
    color: #7A7A7A;
    font-size: 12px;
    margin: 0 1.5rem 0.7rem;
    font-style: italic;
    display: inline-block !important;
    white-space:nowrap;
    max-width: 80% !important;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.review-span {
  font-size: 26px;
  color: #575757;
}

.cancel-icon{
  width: 25px;
}

.accept-icon{
  width: 24px;
}

.review-span,
.cancel-icon,
.accept-icon{
  cursor: pointer;
}


.absence-table {
  width: 100%;
  position: relative;

  tbody tr:hover:nth-child(even) {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.3);
  }

  tbody tr:hover:nth-child(odd) {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.3);
  }

  tbody tr:nth-child(even) {
    background-color: rgba(189, 195, 199, 0.2);
  }

  tbody tr:hover .action-block {
    grid-template-columns: 2.5rem 2.5rem 2.5rem;
    @include display_justify_align(flex, space-between, center)
  }
  td,
  th {
    padding: 11px;
  }


  thead {
    border-bottom: 1px solid #BDBDBD;
    th {
      font-weight: 600;
    }
  }


  .first-column {
    display: flex;
    min-width: 150px;
    max-width: 150px;
  }


  .desc-column {
    width: 40%;
  }


  &_div {
    @include display_justify_align(flex, center, null);
  }
}


tr:nth-child(even) .action-block {
  background-color: #f2f2f2;
}


tr:nth-child(odd) .action-block {
  background-color: white;
}


.action-block {
  display: none;
  @include padding_min-width(14px 30px !important, 80px);
  @include position_right_top(absolute, 0, null);
}


.no-absences{
  color: $main-color;
  font-weight: 600;
  font-size: 20px;
}