@import "src/variable";

.mobile-view {

  overflow-x: hidden;

  .search-by-type {
    .search-type {
      width: 100%;
    }
  }

  .mobile-header{
    width: 100%;
    padding: 0;
  }

  .absence-button {
    background-color: transparent;
    margin-right: 10px;
    @include padding_min-width(2px 11px, null);
    @include border-radius_border(20px, 1px solid #D3D3D3);

  }

  .slick-slide div {
    text-align: center;
  }

  .planned-absence-list {

    margin-bottom: 4rem;

    &_div {
      margin: 20px 0 1rem 20px;
      flex-direction: row;
      @include display_justify_align(flex, space-between, center);
    }
    .prev,.next{
      padding: 0 5px !important;
    }

    .show-more-button {
      margin: 0 0 1rem 1rem;
      @include underlined-button;
    }

    .today-button {
      display: none;
    }

    .current-month {
      min-width: 0 !important;
      box-shadow: none;
      background: none;
      padding: 0;

      .MuiSvgIcon-root {
        font-size: 2rem;
      }
    }

    .month-changer{
      transform: translateY(0px);
    }

    .month-name {
      max-width: 100px !important;
      min-width: 100px !important;
      width: 100px !important;
      padding: 0;
      display: flex !important;
      flex-wrap: wrap !important;
    }

    .month-name p:first-of-type,
    .current-year{
      color: black;
      font-size: 16px;
    }
  }

  .chooseDate,
  .absence-block,
  hr {
    display: none;
  }

  .top-bar {
    border-bottom: 1px solid #E9E9E9;
    margin: 0 20px;
    padding: 10px 0px;
    overflow-x: auto;

    .list,
    .conclusions {
      min-width: auto;
      font-size: 18px;
      width: auto;
      border-bottom: none;
      white-space: nowrap;

      &:hover {
        border-bottom: none;
      }
    }

    .vertical-divider {
      margin: 0 14px 0 10px;
      height: 20px;
      border-left: 1px solid #c8c7c7;
    }

    .number-of-conclusions {
      right: -4px;
    }
  }

  .absence-categories {
    display: -webkit-box;
    overflow: auto;
    margin: 0 20px;
    div{
      button{
        white-space: nowrap;
      }
    }

  }
}

.sent-time-proposal{
  color: #818181;
  font-weight: normal;
  font-size: 14px;
}
.active {
  border: 1px solid $main-color !important;
  @include color_background-color(white, $main-color !important);
}

.employee-record{
  .show-proposal-history-status{
    margin: 0 !important;
  }
}

.show-filter-icon{
  position: absolute;
  right: 10px;
  top: 8px;
  zoom: 1.5;
}

.history-block{
  margin-bottom: 2rem;
}