.mobileTopBar {
    width: 100%;
    height: 70px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    button {
        width: 200px;
    }
}
