$cancel-color: #F36666;
$vacation-color: #F36666;
$planned-absence-color: #FFC46B;
$remote-work-color: #FFC46B;
//$sick-leave-color: #B7D4FF;
$sick-leave-color: #2978A0;
$main-color: #10C998;
$table-color: #C8C8C8;
$weekend-color: rgba(0, 0, 0, .03);

@mixin padding_min-width($padding, $min-width) {
  padding: $padding;
  min-width: $min-width;
}

@mixin display_justify_align($display, $justify-content, $align-items) {
  display: $display;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin border-left_border-right($border) {
  border-left: $border;
  border-right: $border;
}

@mixin border-radius_border($border-radius ,$border) {
  border-radius: $border-radius;
  border: $border;
}

@mixin font-weight_font-size($font-weight, $font-size) {
  font-weight: $font-weight;
  font-size: $font-size;
}

@mixin position_left_top($position, $left, $top) {
  position: $position;
  left: $left;
  top: $top;
}

@mixin position_right_top($position, $right, $top) {
  position: $position;
  right: $right;
  top: $top;
}

@mixin position_right_bottom($position, $right, $bottom) {
  position: $position;
  right: $right;
  bottom: $bottom;
}

@mixin position_left_bottom($position, $left, $bottom) {
  position: $position;
  left: $left;
  bottom: $bottom;
}

@mixin color_background-color($color, $background-color) {
  color: $color;
  background-color: $background-color;
}

@mixin width_height($width, $height) {
  width: $width;
  height: $height;
}

@mixin underlined-button {
  padding: 1px !important;
  border-radius: 0 !important;
  transition: ease-in-out .2s !important;
  border-bottom: 1px solid !important;
  color: #666666;

  &:hover {
    color: $main-color !important;
    border-bottom: 1px solid $main-color;
    text-decoration: none;
    cursor: pointer;
  }
}
@mixin vertical-line {
  position: absolute !important;
  border-radius: 20px !important;
  @include width_height(6px !important , 100% !important);
  margin-bottom: 5px;
}

@mixin textarea-desc {
  padding: 5px 20px;
  position: relative;
  @include border-radius_border(10px, 1px solid #DFDFDF);

  &_title {
    background-color: white;
    padding: 0 5px;
    font-weight: 500;
    @include position_left_top(absolute, null, -13px);
  }

  &_desc {
    color: #505050;
    margin-top: 1.1rem;
    border: none;
    background-color: transparent;
    font-style: italic;
    resize: none;
  }
}
