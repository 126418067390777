.bar_options{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap : 5px;
}

.vertical_divider{
  border-left: 1px solid rgba(0,0,0,.25);
  height: 25px;
  margin: 0 20px 0 8px;
}